import { useParams, useSearchParams } from 'react-router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../icon/icon'
import { Link } from '../internal-link/Internal-link'
import { cn } from '~/utils/misc'

export default function MapShowBtn({ toMap }: { toMap: boolean }) {
	const [searchParams] = useSearchParams({})
	const params = useParams()
	const sp = new URLSearchParams(searchParams.toString())
	const { t } = useTranslation()
	sp.delete('city')
	sp.delete('country')
	let url = '/place?' + sp.toString()
	if (toMap) {
		sp.delete('page')
		if (params.country) sp.append('country', params.country)
		if (params.city) sp.append('city', params.city)
		url = '/map' + (sp.size > 0 ? '?' + sp.toString() : '')
	}
	const [showBtn, setShowBtn] = useState(true)

	useEffect(() => {
		if (!toMap) return
		const handleScroll = () => {
			const windowHeight = window.innerHeight
			const documentHeight = document.documentElement.scrollHeight
			const scrollTop =
				window.scrollY ||
				document.body.scrollTop +
					((document.documentElement && document.documentElement.scrollTop) ||
						0)
			const scrollDistanceFromBottom = documentHeight - windowHeight - scrollTop
			setShowBtn(
				scrollDistanceFromBottom > (window.innerWidth < 768 ? 400 : 100),
			)
		}
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [toMap])

	return (
		<div
			className={cn(
				'pointer-events-none fixed bottom-0 z-10 grid h-52 w-full grid-cols-1 place-items-center duration-300 md:h-32',
				!toMap && 'h-28',
				toMap && !showBtn && 'h-0 opacity-0',
			)}
		>
			<Link
				to={url}
				className={`text-md pointer-events-auto inline-flex h-10 items-center justify-center rounded-3xl border border-border/20 bg-foreground px-4 py-2 font-normal text-background shadow-lg ring-offset-background transition duration-200 hover:scale-110 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2`}
			>
				{!toMap ? t('action_show_list') : t('action_show_map')}
				<Icon
					name={!toMap ? 'list-bullet' : 'globe'}
					className="m-2"
					size="sm"
				/>
			</Link>
		</div>
	)
}
